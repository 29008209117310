  .custom-clerk-link {
      box-sizing: border-box;
      display: inline-flex;
      -moz-box-align: center;
      align-items: center;
      margin: 0px;
      cursor: pointer;
      text-decoration: none;
      font-family: inherit;
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1.25;
      color: rgb(16, 63, 239);
  }

  .custom-clerk-link:hover {
      text-decoration: underline;
      color: rgb(59, 98, 242);
  }

  .custom-clerk-link-label {
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.65);
      margin: 0px;
      font-size: 0.8125rem;
      font-family: inherit;
      font-weight: 400;
      line-height: 1.25;
      margin-right: 0.25em;
  }